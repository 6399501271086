























































































import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import VueHtml2pdf from "vue-html2pdf";
import { notEmpty } from "@/helpers/form.validators";
import { VForm, VueHtml2Pdf } from "@/types";
import RegistrationService from "./services/RegistrationService";
import { ResetPasswordResponse } from "./@types";
import moment from "moment";

@Component({
  components: {
    VueHtml2pdf,
  },
})
export default class ResetMemberPassword extends Vue {
  @Prop({ required: true }) memberNumber!: number;
  @Prop({ default: 550 }) maxWidth!: number;

  @Ref("passwordField") passwordField: VForm | undefined;
  @Ref("html2Pdf") html2Pdf: VueHtml2Pdf | undefined;

  dialog = false;
  loading = false;
  masterPassword = "";

  response: ResetPasswordResponse | null = null;

  error: string | null = null;
  success = false;

  rules = {
    notEmpty: notEmpty,
  };

  close(): void {
    this.masterPassword = "";
    this.resetError();
    if (this.passwordField) {
      this.passwordField.reset();
    }
    this.dialog = false;
    this.success = false;
  }

  formatTimestamp(timestamp: string): string {
    return moment(timestamp).format("DD.MM.YYYY h:mm");
  }

  generatePdfAndClose(): void {
    setTimeout(() => {
      if (this.html2Pdf) {
        this.html2Pdf.generatePdf();
      }
      this.close();
    }, 2000);
  }

  async resetPassword(): Promise<void> {
    this.loading = true;
    try {
      this.response = await RegistrationService.resetMemberPassword(
        this.memberNumber.toString(),
        this.masterPassword
      );
      this.success = true;
      this.generatePdfAndClose();
    } catch (error) {
      this.error = this.$t("registration.reset.error").toString();
    }
    this.loading = false;
  }

  resetError(): void {
    if (this.error) {
      this.error = null;
    }
  }
}
