































import PageTemplate from "@/components/PageTemplate.vue";
import { GemaMember, Registration } from "@/components/Registrations/models";
import RegistrationEditor from "@/components/Registrations/RegistrationEditor.vue";
import RegistrationMemberLookup from "@/components/Registrations/RegistrationMemberLookup.vue";
import RegistrationService from "@/components/Registrations/services/RegistrationService";

import { RouteName } from "@/router/types";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    RegistrationEditor,
    RegistrationMemberLookup,
  },
})
export default class RegistrationsSingular extends Vue {
  RouteName = RouteName;

  loading = true;
  registration: Registration | null = null;

  isNew = false;

  created() {
    this.init();
  }

  @Watch("$route")
  onRouteChange() {
    this.loading = true;
    this.isNew = false;
    this.registration = null;
    this.init();
  }

  async init() {
    this.isNew = this.$route.params.registrationId === "new";

    try {
      if (this.$route.params.memberNumber) {
        const member = await RegistrationService.getGemaMemberById(
          this.$route.params.memberNumber
        );
        this.memberFound(member);
      }

      if (this.isNew) return;

      this.registration = await RegistrationService.getbyId(
        this.$route.params.registrationId
      );
    } catch (error) {
      this.$toast.error("Not found");
      this.$router.push({ name: RouteName.Registrations });
    } finally {
      this.loading = false;
    }
  }

  updateRegistration(registration: Registration) {
    this.registration = registration;
  }

  memberFound(member: GemaMember): void {
    this.registration = Registration.newRegistration(member);
  }
}
