var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.results.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',[_c('v-data-table',{attrs:{"items":_vm.results,"headers":_vm.delegateTableHeaders,"footer-props":{
          'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
          'disable-items-per-page': true,
          'items-per-page-options': [],
        },"items-per-page":5,"disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header-search"},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mb-0"},[_vm._v(" "+_vm._s(_vm.headerTitle)+" ("+_vm._s(_vm.results.length)+") ")]),_c('v-spacer')],1)]},proxy:true},{key:"header.actions",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"d-none"},[_vm._v(_vm._s(header.text))])]}},{key:"item.from",fn:function(ref){
        var item = ref.item;
return [(!_vm.isCurrentRegistration(item))?[_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.preventDefault();return _vm.goToRegistration(item)}}},[_vm._v(" "+_vm._s(_vm.getMemberName(item.member))+" ")])]:[_vm._v(" "+_vm._s(_vm.getMemberName(item.member))+" ")]]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRegistrationStatusLabel(item.registration.status))+" ")]}},{key:"item.registration.lastEditor",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"d-block",staticStyle:{"min-width":"100px"}},[_vm._v(" "+_vm._s(item.registration.lastEditor || _vm.$t("gema.singleUser.lastEditorDefault"))+" ")])]}},{key:"item.createdTimestamp",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.registration.createdTimestamp), "DateTimeShort"))+" ")]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer')]},proxy:true}],null,true)})],1),(_vm.representative)?_c('v-btn',{staticClass:"white--text",attrs:{"small":"","depressed":"","color":"brand","disabled":_vm.exporting},on:{"click":_vm.exportAll}},[_vm._v(" "+_vm._s(_vm.$t( "registrations.representingTable.exportRepresentativeRegistrations" ))+" ")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }