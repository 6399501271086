var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('PageTemplate',{attrs:{"title":_vm.$t('registrations.single.pageTitle'),"to":{
    name: _vm.$route.params.routeName
      ? _vm.$route.params.routeName
      : _vm.RouteName.Registrations,
    query: _vm.$route.params.searchQuery
      ? JSON.parse(_vm.$route.params.searchQuery)
      : undefined,
  }}},[(_vm.isNew && !_vm.registration)?_c('RegistrationMemberLookup',{on:{"memberFound":_vm.memberFound}}):_c('template',{slot:"content"},[_c('v-row',{staticClass:"page-row",attrs:{"no-gutters":""}},[(_vm.registration)?_c('RegistrationEditor',{key:_vm.registration.registration.id,attrs:{"registration":_vm.registration,"isNew":_vm.isNew},on:{"updated":_vm.updateRegistration}}):_vm._e()],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }