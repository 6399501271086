















































import { notEmpty } from "@/helpers/form.validators";
import { VForm } from "@/types";
import { Vue, Component, Ref } from "vue-property-decorator";
import RegistrationService from "./services/RegistrationService";

@Component({})
export default class RegistrationMemberLookup extends Vue {
  @Ref("newRegistrationForm") form!: VForm;

  notEmpty = notEmpty;
  memberNumber = "";
  memberLookupError = "";
  loading = false;

  async fetchUser() {
    if (!this.form.validate() || !this.memberNumber) return;

    try {
      this.memberLookupError = "";
      this.loading = true;
      const member = await RegistrationService.getGemaMemberById(
        this.memberNumber
      );

      this.$emit("memberFound", member);
    } catch (error) {
      this.memberLookupError = this.$i18n
        .t("gema.memberLookup.error")
        .toString();
    } finally {
      this.loading = false;
    }
  }
}
