


























































































































































































































































































































































































































































































































































































































































































































import { YEAR_IN_MS } from "@/helpers/consts";
import {
  emailRules,
  FormRule,
  notEmpty,
  stringLength,
  validateAgeLimit,
} from "@/helpers/form.validators";
import { RouteName } from "@/router/types";
import { VForm, VueHtml2Pdf } from "@/types";
import copy from "fast-copy";
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import { TYPE } from "vue-toastification";
import ConfirmDialog from "../ConfirmDialog.vue";
import VueHtml2pdf from "vue-html2pdf";

import {
  attendanceDayLabels,
  AttendanceType,
  attendanceTypeLabels,
  DelegateStatus,
  MemberStatus,
  OccupationalGroup,
  RegistrationInfo,
  RegistrationResponse,
  RegistrationStatus,
  registrationStatusLabels,
  Representation,
  representationCapacityLabels,
} from "./@types";
import { GemaMember, Registration } from "./models";
import RepresentingTable from "./RepresentingTable.vue";
import ResetMemberPassword from "./ResetMemberPassword.vue";
import RegistrationService from "./services/RegistrationService";
import SideEvents from "./SideEvents.vue";
import { Action, Getter } from "vuex-class";
import { Namespace } from "@/store/types";
import {
  AvailableVotesRequest,
  RegistrationAction,
  RegistrationGetter,
} from "./@types/types";
import PollService from "@/services/api/secure-votes.service";
import { generateRandomId } from "@/spect8-core-vue/src/utils/RandomId";
import { apiChat } from "@/services/api.service";

@Component({
  components: {
    ConfirmDialog,
    RepresentingTable,
    ResetMemberPassword,
    SideEvents,
    VueHtml2pdf,
  },
})
export default class RegistrationEditor extends Vue {
  @Ref("gemaUserForm") form!: VForm;
  @Ref("html2Pdf") htmlToPdf!: VueHtml2Pdf;
  @Prop({ default: false }) readonly isNew!: boolean;
  @Prop() readonly registration!: Registration;

  @Action(
    `${Namespace.Registration}/${RegistrationAction.SetRepresentatorsRegistrations}`
  )
  setRepresentatorsRegistrations!: (
    registrations: RegistrationResponse[]
  ) => Promise<void>;

  @Action(`${Namespace.Registration}/${RegistrationAction.FatchAvailableVotes}`)
  fatchAvailableVotes!: (
    availableVotesRequest: AvailableVotesRequest
  ) => Promise<void>;

  @Action(`${Namespace.Registration}/${RegistrationAction.ResetAvailableVotes}`)
  resetAvailableVotes!: () => Promise<void>;

  @Getter(
    `${Namespace.Registration}/${RegistrationGetter.RepresentatorsRegistrations}`
  )
  readonly representatorsRegistrations!: RegistrationResponse[];

  @Getter(`${Namespace.Registration}/${RegistrationGetter.GetAvailableVotes}`)
  readonly availableVotes!: number;

  images = {
    logo: require("/src/assets/GEMA_Logo_Frei_RGB.jpg"),
    qrCode: require("/src/assets/qr-code-gema-mgv.de.png"),
  };

  registrationDto: Registration = {} as Registration;

  AttendanceType = AttendanceType;
  MemberStatus = MemberStatus;
  YEAR_IN_MS = YEAR_IN_MS;
  DelegateStatus = DelegateStatus;
  RegistrationStatus = RegistrationStatus;
  OccupationalGroup = OccupationalGroup;

  initialising = true;
  editing = false;
  repIsGemaMember = false;
  memberLookupError = "";

  saving = false;
  formValid = true;
  dialog = false;

  artistName = "";

  rule = {
    emailRule: emailRules,
    notEmpty: notEmpty,
    validateBirthDate: validateAgeLimit(
      Date.now() - 18 * YEAR_IN_MS,
      Date.now() - 130 * YEAR_IN_MS
    ),
    passwordLength: stringLength(8, 12),
  };

  async generatePdf() {
    this.dialog = false;
    await this.htmlToPdf.generatePdf();
    this.log();
    this.artistName = "";
  }

  cancelDialog() {
    this.dialog = false;
    this.artistName = "";
  }

  get pdfRepresentorName(): string {
    return this.artistName.trim()
      ? this.artistName.trim()
      : this.representative.representativeFirstName &&
        this.representative.representativeLastName
      ? this.representative.representativeFirstName +
        " " +
        this.representative.representativeLastName
      : this.registration.member.firstName && this.registration.member.lastName
      ? this.registration.member.firstName +
        " " +
        this.registration.member.lastName
      : " ";
  }

  get memberData(): GemaMember {
    return this.registrationDto.member;
  }

  get registrationData(): RegistrationInfo {
    return this.registrationDto.registration;
  }

  get representative(): Representation {
    // TODO: Create Representation
    if (!this.registrationDto.registration.representation) {
      this.registrationDto.registration.representation = {
        // TODO: Speak to Armin, Representatives corrupts Couchbase
        noVotingPermission: false,
        representativeUserId: "",
        representedUserId: "",
        representativeMemberId: "",
        representedMemberId: "",
        representativeFirstName: "",
        representativeLastName: "",
        representativeEmail: "",
        representativeBirthDate: "", // TODO: Confirm date response format
        function: null,
        conflictOfInterestIsUser: false,
        conflictOfInterestIsUserRepresentsInterests: false,
        conflictOfInterestComment: "",
        type: null,
      } as Representation;
    }

    return this.registrationDto.registration.representation;
  }

  // has the user used up his prevotes?
  prevoted = false;

  async created() {
    this.setRegistrationData();
    this.setRepresentatorsRegistrations([]);

    if (this.isNew) {
      this.editing = this.isNew;
    }

    if (
      this.registration.registration.attendanceType ===
        AttendanceType.PRE_VOTE &&
      this.registration.registration.userId
    )
      this.prevoted = await PollService.getQuestions(
        this.registration.registration.userId
      );

    this.$nextTick(() => {
      this.initialising = false;
    });
    if (this.registrationData.userId && this.registrationData.days) {
      const userId = this.registrationData.userId;
      const days = this.registrationData.days;
      this.fatchAvailableVotes({ userId, days });
    } else {
      this.resetAvailableVotes();
    }
  }

  stopEditing() {
    this.editing = !this.editing;
    this.form.resetValidation();
    this.setRegistrationData();
  }

  onBirthDateBlur(date: string | null) {
    if (!date || this.rule.validateBirthDate(date) !== true) return;

    this.registrationDto.member.birthDate = this.formatBirthDate(
      this.registrationDto.member.birthDate
    );

    if (
      this.registrationDto.registration.representation?.representativeBirthDate
    ) {
      this.registrationDto.registration.representation.representativeBirthDate =
        this.formatBirthDate(
          this.registrationDto.registration.representation
            .representativeBirthDate
        );
    }
  }

  setRegistrationData(): void {
    const clonedUser = copy(this.registration);
    clonedUser.member.birthDate = this.formatBirthDate(
      clonedUser.member.birthDate
    );
    clonedUser.member.delegate =
      clonedUser.member.delegate || DelegateStatus.NONE;

    if (clonedUser.registration.representation?.representativeBirthDate) {
      clonedUser.registration.representation.representativeBirthDate =
        this.formatBirthDate(
          clonedUser.registration.representation.representativeBirthDate
        );
    }

    this.registrationDto = clonedUser;
    this.repIsGemaMember = Boolean(
      this.registration.registration.representation?.representativeMemberId
    );
  }

  get birthDateRules(): FormRule[] {
    if (this.memberData.birthDate?.length) {
      return [this.rule.validateBirthDate];
    }

    return [this.rule.notEmpty];
  }

  get delegateStatusOptions(): DelegateStatus[] {
    return Object.values(DelegateStatus);
  }

  get memberStatusOptions(): MemberStatus[] {
    return Object.values(MemberStatus);
  }

  get occupationalGroupOptions(): OccupationalGroup[] {
    return Object.values(OccupationalGroup);
  }

  get registrationStatusOptions(): [string, string][] {
    if (
      this.isNew ||
      this.registration.registration.status === RegistrationStatus.REPRESENTING
    ) {
      return Object.entries(registrationStatusLabels());
    }

    return Object.entries(registrationStatusLabels()).filter(([key]) => {
      return key !== RegistrationStatus.REPRESENTING;
    });
  }

  get attendanceTypeOptions(): [string, string][] {
    return Object.entries(attendanceTypeLabels()).filter(([key]) => {
      if (
        this.memberData.memberStatus === MemberStatus.O &&
        this.memberData.group === OccupationalGroup.V
      ) {
        return [
          AttendanceType.BY_REPRESENTATIVE_IN_PERSON,
          AttendanceType.BY_REPRESENTATIVE_DIGITAL,
          AttendanceType.PRE_VOTE,
        ].includes(key as AttendanceType);
      }
      return true;
    });
  }

  get attendanceDayOptions(): [string, string][] {
    return Object.entries(attendanceDayLabels());
  }

  get representationCapacityOptions(): [string, string][] {
    return Object.entries(representationCapacityLabels());
  }

  get registrationPayload(): Registration {
    return {
      registration: {
        ...this.registrationData,
        representation: !this.registrationData.representation
          ? null
          : {
              ...this.registrationData.representation,
              representativeBirthDate: this.formatBirthDate(
                this.registrationData.representation.representativeBirthDate,
                "-",
                true
              ),
            },
      },
      member: GemaMember.fromGemaMemberReponse({
        ...this.memberData,
        delegate: this.memberData.delegate || null,
        birthDate: this.formatBirthDate(this.memberData.birthDate, "-", true),
      }),
    };
  }

  async findMember() {
    if (!this.representative.representativeMemberId) return;

    this.memberLookupError = "";

    try {
      const member = await RegistrationService.getGemaMemberById(
        this.representative.representativeMemberId
      );

      if (member.group == OccupationalGroup.V) {
        throw new Error("Invalid Member");
      } else {
        this.representative.representativeFirstName = member.firstName || "";
        this.representative.representativeLastName = member.lastName || "";
        this.representative.representativeEmail = member.email || "";
        this.representative.representativeBirthDate =
          this.formatBirthDate(member.birthDate) || "";
      }
    } catch (error) {
      this.representative.representativeFirstName = "";
      this.representative.representativeLastName = "";
      this.representative.representativeEmail = "";
      this.representative.representativeBirthDate = "";
      this.memberLookupError = this.$i18n
        .t("gema.memberLookup.error")
        .toString();
    }
  }

  async saveChanges() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.saving = true;
      this.editing = false;
      let savedRegistration!: Registration;

      if (this.isNew) {
        savedRegistration = await RegistrationService.create(
          this.registrationPayload
        );

        await new Promise((resolve) => {
          this.$toast(this.$i18n.t("registrations.toast.registrationCreated"), {
            timeout: 3000,
            type: TYPE.SUCCESS,
          });
          setTimeout(resolve, 250);
        });

        this.$router.replace({
          name: RouteName.RegistrationsSingle,
          params: {
            registrationId: savedRegistration.registration.id || "",
          },
        });

        return;
      } else {
        savedRegistration = await RegistrationService.patch(
          this.registrationPayload
        );
      }

      this.$emit("updated", savedRegistration);

      this.saving = false;
    } catch (error) {
      this.saving = false;
      this.editing = true;
      //TODO: GERMANISE ERROR MESSAGE
      this.$toast.error("Something has gone wrong... etc");
    }
  }

  get representationHeading(): string {
    const { memberStatus, group } = this.memberData;

    switch (true) {
      case memberStatus === MemberStatus.O: {
        return this.$i18n.t("gema.singleUser.representation").toString();
      }
      case memberStatus === MemberStatus.A && group === OccupationalGroup.V: {
        return this.$i18n
          .t("gema.singleUser.excerciseOfVotingRights")
          .toString();
      }

      default:
        return "";
    }
  }

  get showRepIsGemaMemberFields(): boolean {
    const { memberStatus, group } = this.memberData;
    if (memberStatus !== MemberStatus.O) return false;

    if (group && [OccupationalGroup.K, OccupationalGroup.T].includes(group))
      return true;

    return false;
  }

  get showRepresentationSection(): boolean {
    if (!this.registrationData) return false;

    const { memberStatus, group } = this.memberData;
    const { attendanceType } = this.registrationData;

    if (!attendanceType || !memberStatus || !group) return false;

    if (
      ![
        AttendanceType.BY_REPRESENTATIVE_DIGITAL,
        AttendanceType.BY_REPRESENTATIVE_IN_PERSON,
      ].includes(attendanceType)
    )
      return false;

    if (memberStatus === MemberStatus.A && group !== OccupationalGroup.V)
      return false;

    return true;
  }

  formatBirthDate(date: string | null, join = ".", reverse = false): string {
    if (!date) return "";

    let mm = "";
    let dd = "";
    let yyyy = "";

    if (date.indexOf("-") > -1) {
      const dateParts = date.split("-");
      yyyy = dateParts[0];
      mm = dateParts[1];
      dd = dateParts[2];
    }

    if (date.indexOf("/") > -1) {
      const dateParts = date.split("/");
      dd = dateParts[0];
      mm = dateParts[1];
      yyyy = dateParts[2];
    }

    if (date.indexOf(".") > -1) {
      const dateParts = date.split(".");
      dd = dateParts[0];
      mm = dateParts[1];
      yyyy = dateParts[2];
    }

    if (dd && mm && yyyy) {
      dd = ("0" + dd).slice(-2);
      mm = ("0" + mm).slice(-2);
      const dateChunks = reverse ? [dd, mm, yyyy].reverse() : [dd, mm, yyyy];
      return dateChunks.join(join);
    }

    console.warn(`Could not format birth date - formatBirthDate(${date})`);
    return date;
  }

  @Watch("registrationData.attendanceType")
  onAttendanceTypeChange(type: AttendanceType) {
    const selectedOptionExists = this.attendanceTypeOptions.some(
      ([key]) => key === type
    );
    if (!selectedOptionExists) {
      this.registrationData.attendanceType = null;
    }
  }

  @Watch("repIsGemaMember")
  onRepIsGemaMemberChange() {
    this.memberLookupError = "";

    if (!this.repIsGemaMember && this.representative.representativeMemberId) {
      this.representative.representativeMemberId = "";
    }

    if (!this.initialising) {
      this.representative.representativeFirstName = "";
      this.representative.representativeLastName = "";
      this.representative.representativeEmail = "";
      this.representative.representativeBirthDate = "";
      this.form.resetValidation();
    }
  }

  get memberRepTableHeader(): string {
    const { memberStatus, group } = this.memberData;

    switch (true) {
      case memberStatus === MemberStatus.O &&
        group &&
        [OccupationalGroup.K, OccupationalGroup.T].includes(group): {
        return this.$i18n.t("gema.singleUser.representation").toString();
      }
      default:
        return this.$i18n
          .t("gema.singleUser.excerciseOfVotingRights")
          .toString();
    }
  }

  // Resend button
  resendLoading = false;

  get confirmed(): boolean {
    return (
      this.registration.registration.status === RegistrationStatus.CONFIRMED
    );
  }

  resendMail() {
    if (!this.registrationData.id) return;
    this.resendLoading = true;
    RegistrationService.resendMail(this.registrationData.id).then(() => {
      this.resendLoading = false;
      // TODO: translate
      this.$toast.success("E-Mail gesendet.");
    });
  }

  randomEmail() {
    const email = `mgv${generateRandomId()}@spect8.com`;
    this.memberData.email = email;
  }

  async log(): Promise<void> {
    const info = {
      memberNumber: this.memberData.memberNumber,
      userId: this.registrationData.userId,
      memberStatus: this.memberData.memberStatus,
      memberGroup: this.memberData.group,
      memberDelegateStatus: this.memberData.delegate || null,
      numAvailableVotes: this?.availableVotes ?? 0,
    };
    await apiChat.logAkkred(info);
    // console.log(info);
  }
}
