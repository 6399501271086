


























































































import { RouteName } from "@/router/types";
import { Namespace } from "@/store/types";
import { VTableHeaders } from "@/types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import {
  OccupationalGroup,
  RegistrationResponse,
  RegistrationStatus,
  registrationStatusLabels,
  Representation,
} from "./@types";
import { RegistrationAction } from "./@types/types";
import { GemaMember } from "./models";

import RegistrationService from "./services/RegistrationService";

@Component
export default class RepresentingTable extends Vue {
  @Prop({ required: true }) readonly representedMember!: GemaMember;
  @Prop({ default: null }) readonly representative!: Representation | null;
  @Prop() readonly userId!: string;
  @Prop({ required: true }) readonly headerTitle!: string;

  @Action(
    `${Namespace.Registration}/${RegistrationAction.SetRepresentatorsRegistrations}`
  )
  setRepresentatorsRegistrations!: (
    registrations: RegistrationResponse[]
  ) => Promise<void>;

  RouteName = RouteName;
  OccupationalGroup = OccupationalGroup;

  results: RegistrationResponse[] = [];
  loading = true;
  exporting = false;

  async created() {
    this.results =
      await RegistrationService.getRegsistrationsByMemberNumberOrUserId(
        this.userId
      );
    this.setRepresentatorsRegistrations(this.results);

    this.loading = false;
  }

  get registrationStatusLabels(): Record<RegistrationStatus, string> {
    return registrationStatusLabels();
  }

  get delegateTableHeaders(): VTableHeaders[] {
    return [
      {
        text: this.$t("gema.singleUser.voteTable.from").toString(),
        value: "from",
        width: 200,
      },
      {
        text: this.$t("gema.table.header.registrationStatus").toString(),
        value: "status",
        width: 200,
      },
      {
        text: this.$t("gema.singleUser.lastEditor").toString(),
        value: "registration.lastEditor",
      },
      {
        text: this.$t("gema.table.header.created").toString(),
        value: "createdTimestamp",
        width: 200,
      },
    ];
  }

  getRegistrationStatusLabel(status: RegistrationStatus): string {
    return this.registrationStatusLabels[status] || "~";
  }

  getMemberName(member: GemaMember): string {
    return member
      ? member.group === OccupationalGroup.V
        ? member.publisherName || ""
        : [member.firstName, member.lastName].join(" ")
      : "";
  }

  goToRegistration(item: RegistrationResponse) {
    this.$router.push({
      name: RouteName.RegistrationsSingle,
      params: {
        registrationId: item.registration.id || "",
      },
    });
  }

  isCurrentRegistration(item: RegistrationResponse): boolean {
    return (
      item.registration.representation?.representedMemberId ===
      this.representedMember.memberNumber
    );
  }

  async exportAll() {
    const representative = this.representative as Representation;

    this.exporting = true;
    await RegistrationService.exportRepresentativeRegistrations(
      this.userId,
      [
        representative.representativeFirstName,
        representative.representativeLastName,
      ]
        .join("_")
        .trim()
        .toLowerCase()
    );
    setTimeout(() => {
      this.exporting = false;
    }, 500);
  }
}
