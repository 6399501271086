



















































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GemaMember } from "./models";
import {
  OccupationalGroup,
  SideEventAttendance,
  SideEventOption,
} from "./@types";
import { emailNotRequiredRules } from "@/helpers/form.validators";

@Component({})
export default class SideEvents extends Vue {
  @Prop() memberDto!: GemaMember;

  OccupationalGroup = OccupationalGroup;
  SideEventOption = SideEventOption;
  emailNotRequiredRules = emailNotRequiredRules;

  get sideEventOptions(): SideEventOption[] {
    if (this.isMtMember || this.isATDDelegate) {
      return [
        SideEventOption.memberFestival,
        SideEventOption.roadshow,
        SideEventOption.technicalTraining,
      ];
    }

    return Object.values(SideEventOption);
  }

  sideEventLabel(
    key: SideEventOption | string,
    group?: OccupationalGroup
  ): string {
    const type: string = ((type: OccupationalGroup | null) => {
      switch (type) {
        case OccupationalGroup.T:
          return "Lyricist";
        case OccupationalGroup.V:
          return "Publisher";
        case OccupationalGroup.K:
          return "Composer";
      }
      return "";
    })(group || this.memberDto.group);

    switch (key) {
      case SideEventOption.memberFestival:
        return this.$i18n.t("gema.sideEvent.memberFestival").toString();
      case SideEventOption.roadshow:
        return this.$i18n.t("gema.sideEvent.roadshow").toString();
      case SideEventOption.technicalTraining:
        return this.$i18n.t("gema.sideEvent.technicalTraining").toString();
      case SideEventOption.curiaConsultationHour:
        return this.$i18n
          .t(`gema.sideEvent.curiaConsultationHour${type}`)
          .toString();
      case SideEventOption.occupationalGroupGathering:
        return this.$i18n
          .t(`gema.sideEvent.occupationalGroupGathering${type}`)
          .toString();
      default:
        return key.toString();
    }
  }

  get eventData(): SideEventAttendance {
    return (
      this.memberDto.sideEventAttendance || {
        memberFestival: false,
        roadshow: false,
        technicalTraining: false,
        curiaConsultationHour: false,
        occupationalGroupGathering: false,
        mtChosenOccupationalGroup: null,
        infoEvent1: null,
        infoEvent2: null,
        infoEvent3: null,
        email: "",
      }
    );
  }

  get isOccupationGroupGatheringT(): boolean {
    return (
      (this.isMtMember || this.isATDDelegate) &&
      this.eventData.mtChosenOccupationalGroup == OccupationalGroup.T
    );
  }

  get isOccupationGroupGatheringK(): boolean {
    return (
      (this.isMtMember || this.isATDDelegate) &&
      this.eventData.mtChosenOccupationalGroup == OccupationalGroup.K
    );
  }

  setOccupationalGroupGatheringForMT(group: OccupationalGroup, value: boolean) {
    this.eventData[SideEventOption.occupationalGroupGathering] = value;
    this.eventData.mtChosenOccupationalGroup = value ? group : null;
  }

  @Watch("eventData", { deep: true, immediate: true })
  onEventDataChange() {
    if (!this.memberDto.sideEventAttendance) {
      this.$emit("change", this.eventData);
      return;
    }
  }

  get isMtMember(): boolean {
    const { memberStatus, group, delegate } = this.memberDto;
    return (
      memberStatus == "A" &&
      (group == "MT" || group == "K" || group == "T") &&
      !delegate
    );
  }

  get isATDDelegate(): boolean {
    const { memberStatus, group, delegate } = this.memberDto;
    return memberStatus == "A" && group == "T" && delegate ? true : false;
  }

  infoEvents = {
    infoEvent1: {
      title: "Event 1: 17.05.2022 von 11:30 – 12:30 Uhr",
      options: [
        {
          title: "wieso bin ich hier?",
        },
      ],
    },
    infoEvent2: {
      title: "Event 2: 18.05.2022 von 8:30 – 9:30 Uhr",
      options: [
        { title: "Keine Auswahl", value: null },
        {
          title: "Neuregelung Bearbeiterbeteiligung",
          value: 1,
        },
        {
          title: "Verteilungsplan-Antrag zu Werk-Anmeldefristen Online",
          value: 2,
        },
        {
          title:
            "Veranstaltungs-Crawler MESLIS, Vorstellung Theaterportal, SETUP: Einreichung von Setlists",
          value: 3,
        },
      ],
    },
    infoEvent3: {
      title: "Event 3: 19.05.2022 von 8:30 – 9:30 Uhr",
      options: [
        { title: "Keine Auswahl", value: null },
        {
          title: "Aktuelle Entwicklungen und Abrechnungsmodelle im Streaming",
          value: 1,
        },
        {
          title: "Monitoring und Soundfile Upload, AV-Anmeldung und AV-Anzeige",
          value: 2,
        },
        {
          title:
            "Neuigkeiten aus dem GEMA Onlineportal: Ein Meet & Greet für Urheber und Musiknutzer.",
          value: 3,
        },
      ],
    },
  };

  info1checkbox(ev: boolean) {
    if (ev) this.eventData.infoEvent1 = 1;
    else this.eventData.infoEvent1 = null;
    console.log(this.eventData);
  }
}
