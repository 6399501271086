var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-3"},[_c('v-row',{staticClass:"pt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("gema.sideEvent.title"))+" ")]),_vm._l((_vm.sideEventOptions),function(key,index){return _c('v-col',{key:("checkbox_" + key + "_" + index),attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"color":"brand","label":_vm.sideEventLabel(key),"dense":"","hide-details":"auto"},model:{value:(_vm.eventData[key]),callback:function ($$v) {_vm.$set(_vm.eventData, key, $$v)},expression:"eventData[key]"}})],1)}),(_vm.isATDDelegate || _vm.isMtMember)?[_c('v-col',{key:("checkbox_" + (_vm.SideEventOption.occupationalGroupGathering) + "_" + (_vm.OccupationalGroup.T)),attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"color":"brand","input-value":_vm.isOccupationGroupGatheringT,"label":_vm.sideEventLabel(
              _vm.SideEventOption.occupationalGroupGathering,
              _vm.OccupationalGroup.T
            ),"dense":"","hide-details":"auto"},on:{"click":function($event){return _vm.setOccupationalGroupGatheringForMT(
              _vm.OccupationalGroup.T,
              !_vm.isOccupationGroupGatheringT
            )}}})],1),(!_vm.isATDDelegate)?_c('v-col',{key:("checkbox_" + (_vm.SideEventOption.occupationalGroupGathering) + "_" + (_vm.OccupationalGroup.K)),attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"color":"brand","input-value":_vm.isOccupationGroupGatheringK,"label":_vm.sideEventLabel(
              _vm.SideEventOption.occupationalGroupGathering,
              _vm.OccupationalGroup.K
            ),"dense":"","hide-details":"auto"},on:{"click":function($event){return _vm.setOccupationalGroupGatheringForMT(
              _vm.OccupationalGroup.K,
              !_vm.isOccupationGroupGatheringK
            )}}})],1):_vm._e()]:_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"color":"brand","label":_vm.infoEvents.infoEvent1.title,"dense":"","hide-details":"auto"},on:{"change":_vm.info1checkbox},model:{value:(_vm.eventData.infoEvent1),callback:function ($$v) {_vm.$set(_vm.eventData, "infoEvent1", $$v)},expression:"eventData.infoEvent1"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"6"}},[_c('v-select',{staticClass:"pa-3",attrs:{"label":_vm.infoEvents.infoEvent2.title,"items":_vm.infoEvents.infoEvent2.options,"item-text":"title"},model:{value:(_vm.eventData.infoEvent2),callback:function ($$v) {_vm.$set(_vm.eventData, "infoEvent2", $$v)},expression:"eventData.infoEvent2"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"6"}},[_c('v-select',{staticClass:"pa-3",attrs:{"label":_vm.infoEvents.infoEvent3.title,"items":_vm.infoEvents.infoEvent3.options,"item-text":"title"},model:{value:(_vm.eventData.infoEvent3),callback:function ($$v) {_vm.$set(_vm.eventData, "infoEvent3", $$v)},expression:"eventData.infoEvent3"}})],1),_c('v-row',{staticClass:"mt-0 px-5"},[_c('v-text-field',{attrs:{"persistent-placeholder":"","label":_vm.$t('gema.singleUser.emailSideevents'),"hide-details":"auto","dense":"","color":"brand","rules":[_vm.emailNotRequiredRules]},model:{value:(_vm.eventData.email),callback:function ($$v) {_vm.$set(_vm.eventData, "email", $$v)},expression:"eventData.email"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }